import AppUser from "./pages/appUser";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <AppUser />
    </ApolloProvider>
  );
}
