import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useLazyQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import { CMS_GET_APP_USER } from "../gql/appUser";
import { calculatePage } from "../func/calculatePage";
import ModalAppUser from "../components/appUser/modalAppUser";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Box, Pagination, TextField, Typography } from "@mui/material";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

let timer;

export default function AppUser() {
  const [modal, setModal] = React.useState(false);
  const [cards, setCards] = React.useState([]);
  const [editData, setEditData] = React.useState(false);

  const [limit, setLimit] = React.useState(10);
  const [count, setCount] = React.useState(0);

  const [page, setPage] = React.useState(1);
  const [keyword, setKeyword] = React.useState("");

  const gridRef = React.useRef();

  const [getUser] = useLazyQuery(CMS_GET_APP_USER, {
    onCompleted: (data) => {
      const checkApi = data.cmsFetchUserAccount.payload;
      setCards(checkApi.userData);
      setCount(checkApi.count);

      gridRef.current.api.hideOverlay();
    },
  });
  const defaultColDef = { sortable: true };

  const mobileFormat = (param) => {
    return `${param.data.user_mobile_country_code} ${param.data.user_mobile_number}`;
  };

  const checkHaveValue = (param) => {
    if (param.value) return <CheckBox color="primary" />;
    return <CheckBoxOutlineBlank />;
  };

  const viewDetail = (e) => {
    return (
      <EditIcon
        onClick={() => {
          setEditData(e.data), setModal(true);
        }}
      />
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "user_account_id",
    },
    {
      headerName: "First name",
      field: "user_firstname",
    },
    {
      headerName: "Last name",
      field: "user_lastname",
    },
    {
      headerName: "Email",
      field: "user_email",
    },

    {
      headerName: "Tel",
      field: "user_mobile_number",
      valueGetter: mobileFormat,
    },
    {
      headerName: "Verify emal",
      field: "verify_email",
      cellRenderer: checkHaveValue,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      headerName: "Verify mobile",
      field: "verify_mobile_number",
      cellRenderer: checkHaveValue,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      headerName: "Apple",
      field: "apple_id",
      cellRenderer: checkHaveValue,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      headerName: "Google",
      field: "google_id",
      cellRenderer: checkHaveValue,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      headerName: "Facebook",
      field: "facebook_id",
      cellRenderer: checkHaveValue,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      headerName: "Edit Data",
      cellRenderer: viewDetail,
      pinned: "right",
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];

  const { totalPage, startRow, endRow, offset } = calculatePage({
    count,
    limit,
    page,
  });

  React.useMemo(() => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      gridRef.current.api.showLoadingOverlay();
      getUser({
        variables: {
          keyword: keyword,
          limit: limit,
          offset: offset,
        },
      });
    }, 500);
  }, [page, keyword]);

  return (
    <Box px={1}>
      {modal && (
        <ModalAppUser
          modal={modal}
          editData={editData}
          handleClose={() => (setEditData(false), setModal(false))}
          reload={() => {
            getUser({
              variables: {
                keyword: keyword,
                limit: limit,
                offset: 0,
              },
            });
          }}
        />
      )}

      <Box my={2}>
        <TextField
          onChange={(e) => {
            setKeyword(e.target.value);
            setPage(1);
          }}
          label="Search"
          variant="outlined"
          fullWidth
        />
      </Box>
      <div className="ag-theme-alpine" style={{ height: 486 }}>
        <AgGridReact
          ref={gridRef}
          rowData={cards}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          suppressDragLeaveHidesColumns
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            border: 1,
            py: 1.5,
            px: 3,
            borderColor: "grey.400",
            borderTopColor: "transparent",
          }}
        >
          <Typography
            children={`${startRow} to ${endRow} of ${count}`}
            variant="caption"
          />
        </Box>
        <Box
          display={cards.length > 0 ? "flex" : "none"}
          justifyContent="center"
          mt={5}
        >
          <Pagination
            count={totalPage}
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </Box>
      </div>
    </Box>
  );
}
