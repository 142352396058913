import React from "react";
import {
  Switch,
  TextField,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

import ItemInSelect from "../components/select";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function LayoutForm({
  formik,
  label,
  formType,
  fieldKey,
  fieldArray,
  checkDisabled = false,
  checkCondition,
}) {
  const splitKey = fieldKey.split(".");
  const checkError = splitKey[1]
    ? formik.errors[`${splitKey[0]}`]?.[`${splitKey[1]}`] || false
    : formik.errors[`${fieldKey}`] || false;

  switch (formType) {
    case "textArea":
      return (
        <TextField
          label={label}
          type={formType}
          fullWidth
          margin="dense"
          multiline
          rows={3}
          {...formik.getFieldProps(fieldKey)}
          disabled={checkDisabled}
          error={checkError}
        />
      );
    case "switch":
      return (
        <FormControlLabel
          control={
            <Switch
              required={checkError}
              disabled={checkDisabled}
              {...formik.getFieldProps(fieldKey)}
              checked={formik.getFieldProps(fieldKey).value}
            />
          }
          label={label}
          sx={{ margin: 0, py: 2 }}
        />
      );

    case "select":
      let checkGetSelect = checkCondition ? checkCondition : fieldKey;

      const getSelctItem = ItemInSelect({ fieldType: checkGetSelect });

      if (!getSelctItem) return "loading...";
      return (
        <Autocomplete
          options={getSelctItem}
          renderInput={(params) => <TextField {...params} label={label} />}
          value={
            getSelctItem.find(
              (row) => row.value == formik.getFieldProps(fieldKey).value
            ) || ""
          }
          onChange={(e, newValue) =>
            formik.setFieldValue(fieldKey, newValue?.value)
          }
          sx={{ my: 1 }}
          disabled={checkDisabled}
          error={checkError}
        />
      );

    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label={label}
              value={dayjs(formik.getFieldProps(fieldKey).value)}
              onAccept={(e) => {
                formik.setFieldValue(fieldKey, dayjs(e).format("YYYY-MM-DD"));
              }}
              disabled={checkDisabled}
              sx={{ width: "100%" }}
              maxDate={dayjs()}
            />
          </DemoContainer>
        </LocalizationProvider>
      );

    default:
      return (
        <TextField
          fullWidth
          label={label}
          margin="dense"
          type={formType}
          error={Boolean(checkError)}
          disabled={checkDisabled}
          {...formik.getFieldProps(fieldKey)}
        />
      );
  }
}
