import { useQuery } from "@apollo/client";

import { GET_COUNTRIES } from "../../gql/countries";

// import { genderData } from "../../config/data/genderData";

export default function ItemInSelect({ fieldType }) {
  const filterType = fieldType.split(/[.,[]/)[0];
  let getValue = [];

  switch (filterType) {
    // case "user_gender":
    //   return genderData.map((row) => {
    //     return {
    //       value: row.value,
    //       label: row.label,
    //     };
    //   });

    case "user_nationality_code":
      getValue = useQuery(GET_COUNTRIES);
      return getValue?.data?.cmsGetCountries?.payload.map((row) => {
        return {
          value: row.country_code,
          label: row.name_en,
        };
      });

    default:
      return false;
  }
}
