import React from "react";

import Swal from "sweetalert2";
import LayoutForm from "../layoutForm";
import { useMutation } from "@apollo/client";
import { validateUpdateUser } from "../../func";
import { Formik, Form, useFormik } from "formik";
import { CMS_UPDATE_APP_USER } from "../../gql/appUser";
import { modalActionStyle } from "../../config/modalStyle";
import { staticField } from "../../config/formConfig/appUser";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal, Box, Grid, Button, Backdrop } from "@mui/material";

export default function ModalAppUser({
  modal,
  handleClose,
  editData = false,
  reload,
}) {
  const [pageLoading, setPageLoading] = React.useState(false);
  const [updateUser] = useMutation(CMS_UPDATE_APP_USER);

  let formikValue = {};
  for (const temp of [...staticField]) {
    formikValue[temp.key] = editData ? editData[temp.key] : temp.value;
  }
  let resetField = {};
  for (const temp of [...staticField]) {
    resetField[temp.key] = temp.value;
  }

  const formik = useFormik({
    initialValues: formikValue,
    validationSchema: validateUpdateUser,
    onSubmit: async (values) => {
      try {
        setPageLoading(true);
        const { data } = await updateUser({
          variables: { ...values, user_account_id: editData.user_account_id },
        });

        const apiData = data.cmsUpdateUser;
        if (!apiData.success) throw new Error(apiData.message);
        setPageLoading(false);
        reload();
        handleClose();
        Swal.fire("Success", "Modify App user success", "success");
      } catch (e) {
        setPageLoading(false);
        handleClose();

        Swal.fire({ icon: "error", title: "Oops...", text: e.message });
      }
    },
  });
  const setInitialValue = () => {
    formik.resetForm({ values: resetField });
  };
  return (
    <Modal disableEnforceFocus open={modal}>
      <Box
        sx={{ ...modalActionStyle, overflowY: pageLoading ? "hidden" : "auto" }}
      >
        <Formik
          initialValues={formik.initialValues}
          onSubmit={formik.handleSubmit}
        >
          <Form>
            <Grid container columnSpacing={1}>
              {staticField.map((row, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={(12 / 100) * parseFloat(row.fieldWidth)}
                  >
                    <LayoutForm
                      key={index}
                      formType={row.formType}
                      label={row.label}
                      fieldWidth={row.fieldWidth}
                      formik={formik}
                      fieldKey={row.key}
                      fieldArray={row?.fieldArray}
                      checkDisabled={row?.checkDisabled}
                      checkCondition={row?.condition}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Box display="flex" justifyContent="space-evenly" mt={2}>
              <Button variant="contained" type="submit" sx={{ width: 150 }}>
                Save
              </Button>
              <Button
                variant="contained"
                sx={{ width: 150 }}
                onClick={() => {
                  setInitialValue();
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        </Formik>
        {pageLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={pageLoading}
          >
            <CircularProgress />
          </Backdrop>
        )}
      </Box>
    </Modal>
  );
}
