export const modalActionStyle = {
  p: 4,
  width: "80%",
  maxHeight: "90%",
  top: "50%",
  left: "50%",
  boxShadow: 24,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
};
