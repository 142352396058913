import { gql } from "@apollo/client";

export const CMS_GET_APP_USER = gql`
  query CmsFetchUserAccount($limit: Int, $offset: Int, $keyword: String) {
    cmsFetchUserAccount(limit: $limit, offset: $offset, keyword: $keyword) {
      code
      message
      success
      payload {
        count
        userData {
          user_account_id
          user_firstname
          user_lastname
          user_email
          verify_email
          user_mobile_country_code
          user_mobile_number
          verify_mobile_number
          apple_id
          google_id
          facebook_id
          user_nationality_code
          user_dob
        }
      }
    }
  }
`;

export const CMS_UPDATE_APP_USER = gql`
  mutation Mutation(
    $user_account_id: String
    $user_firstname: String
    $user_lastname: String
    $user_mobile_country_code: String
    $user_mobile_number: String
    $user_email: String
    $verify_email: Boolean
    $verify_mobile_number: Boolean
    $user_dob: String
    $user_nationality_code: String
  ) {
    cmsUpdateUser(
      user_account_id: $user_account_id
      user_firstname: $user_firstname
      user_lastname: $user_lastname
      user_mobile_country_code: $user_mobile_country_code
      user_mobile_number: $user_mobile_number
      user_email: $user_email
      verify_email: $verify_email
      verify_mobile_number: $verify_mobile_number
      user_dob: $user_dob
      user_nationality_code: $user_nationality_code
    ) {
      success
      message
      code
    }
  }
`;
