export const calculatePage = ({ count, limit, page }) => {
  const totalPage = count / limit > 1 ? Math.ceil(count / limit) : 1;

  let startRow = count > 0 ? 1 : 0;
  let endRow = 0;

  if (page > 1) startRow = limit * (page - 1) + 1; // If now page more than 1 calculate start at row data to show record

  endRow = startRow + limit - 1;
  if (page === totalPage) endRow = count; // Last page show sum total data

  let offset = page > 1 ? startRow - 1 : 0; // If now page more than 1 calculate start row to send to api

  return {
    totalPage,
    startRow,
    endRow,
    offset,
  };
};
