export const staticField = [
  // {
  //   key: "user_gender",
  //   label: "User gender",
  //   fieldWidth: "20%",
  //   formType: "select",
  //   value: "",
  // },
  {
    key: "user_firstname",
    label: "First name",
    fieldWidth: "50%",
    formType: "text",
    value: "",
  },
  {
    key: "user_lastname",
    label: "Last name",
    fieldWidth: "50%",
    formType: "text",
    value: "",
  },
  {
    key: "user_mobile_country_code",
    label: "Mobile country code",
    fieldWidth: "30%",
    formType: "text",
    value: "",
  },
  {
    key: "user_mobile_number",
    label: "Mobile number",
    fieldWidth: "70%",
    formType: "text",
    value: "",
  },
  {
    key: "user_nationality_code",
    label: "User Nationality code",
    fieldWidth: "30%",
    formType: "select",
    value: "",
  },
  {
    key: "user_email",
    label: "User Email",
    fieldWidth: "70%",
    formType: "text",
    value: "",
  },
  {
    key: "user_dob",
    label: "User DOB",
    fieldWidth: "100%",
    formType: "date",
    value: "",
  },
  {
    key: "verify_email",
    label: "User Verify Email",
    fieldWidth: "50%",
    formType: "switch",
    value: false,
  },
  {
    key: "verify_mobile_number",
    label: "User Verify Mobile number",
    fieldWidth: "50%",
    formType: "switch",
    value: false,
  },
  {
    key: "apple_id",
    label: "Apple id",
    fieldWidth: "34%",
    formType: "text",
    value: "",
    checkDisabled: true,
  },
  {
    key: "google_id",
    label: "Google id",
    fieldWidth: "33%",
    formType: "text",
    value: "",
    checkDisabled: true,
  },
  {
    key: "facebook_id",
    label: "Facebook id",
    fieldWidth: "33%",
    formType: "text",
    value: "",
    checkDisabled: true,
  },
];
