import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query CmsGetCountries {
    cmsGetCountries {
      code
      message
      success
      payload {
        country_id
        country_code
        name_en
        calling_country_code
      }
    }
  }
`;
